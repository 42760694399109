<template>
  <v-container fluid>
    <v-row justify="center">
      <div class="text-center">
        <h3 class="text-h4 mb-5 text-uppercase">Pricelist</h3>
        Ce sont les prix standards, tout dépend des détails, de la difficulté...
        ;) <br />Demandez moi !
      </div>
    </v-row>
    <v-row justify="center" class="mt-16">
      <v-col cols="6">
        <v-carousel cycle class="grey lighten-4">
          <v-carousel-item
            v-for="(item, i) in imgHead"
            :key="i"
            :src="item.src"
            contain
          ></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="6" align-self="center">
        <div>
          <h1 class="text-h5 text-uppercase">Headshot</h1>
          <ul class="mb-5">
            <li>Flat color - 20€</li>
            <li>Shading - 30€</li>
          </ul>
          Additional character +50%
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-16">
      <v-col cols="6">
        <v-carousel cycle>
          <v-carousel-item
            v-for="(item, i) in imgHalf"
            :key="i"
            :src="item.src"
            contain
          ></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="6" align-self="center">
        <div>
          <h1 class="text-h5 text-uppercase">Halfbody</h1>
          <ul class="mb-5">
            <li>Flat color - 35€</li>
            <li>Shading - 45€</li>
          </ul>
          Additional character +50% <br />Simple Background +5€ <br />Anthro +5€
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-16">
      <v-col cols="6">
        <v-carousel cycle>
          <v-carousel-item
            v-for="(item, i) in imgFull"
            :key="i"
            :src="item.src"
            contain
          ></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="6" align-self="center">
        <div>
          <h1 class="text-h5 text-uppercase">Fullbody</h1>
          <ul class="mb-5">
            <li>Flat color - 50€</li>
            <li>Shading - 60€</li>
          </ul>
          Additional character +50% <br />Simple Background +5€ <br />Detailed
          background +15€ <br />Anthro +5€
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-16">
      <v-col cols="6">
        <v-carousel cycle>
          <v-carousel-item
            v-for="(item, i) in imgRef"
            :key="i"
            :src="item.src"
            contain
          ></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="6" align-self="center">
        <div>
          <h1 class="text-h5 text-uppercase">Reference sheet</h1>
          <ul class="mb-5">
            <li>Anthro > 2 fullbodies (front/back), 1 headshot - 70€</li>
            <li>Feral > 2 sides, 1 headshot - 50€</li>
          </ul>
          +1 headshot - 5€ <br />+1 fullbody - 10€ <br />+ Accessories - 5€
          <br />
          <br />No shading will be added
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import imjson from '@/assets/imagesJson.json'

export default {
  data: () => ({
    images: imjson
  }),
  computed: {
    imgHead: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'head') {
          tab.push(image)
        }
      })
      return tab
    },
    imgHalf: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'half') {
          tab.push(image)
        }
      })
      return tab
    },
    imgFull: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'full') {
          tab.push(image)
        }
      })
      return tab
    },
    imgRef: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'ref') {
          tab.push(image)
        }
      })
      return tab
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: square;
}
</style>
